import DetectBrowserMixin from '~/mixins/DetectBrowserMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'

export default {
  mixins: [DetectBrowserMixin, ImageUrlMixin, ScrollToEventMixin],
  props: {
    itemprop: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    srcsetBreakpoints: Array,
    alt: {
      type: String,
      default: '',
    },
    imageAriaLabelledBy: String,
    imageClass: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    crop: {
      type: Boolean,
      default: false,
    },
    upscale: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    lazyThumbnail: {
      type: Boolean,
      default: false,
    },
    imageStyle: {
      type: String,
      default: 'custom',
    },
    title: {
      type: String,
      required: false,
    },
    webpDisabled: Boolean,
  },
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    imageRatio() {
      return this.width && this.height ? 1 / (this.width / this.height) : null
    },
    imageUrlSettings() {
      return {
        crop: this.crop,
        imageStyle: this.imageStyle,
        src: this.src || 'no-image.png',
        width: this.imageStyle !== 'original' ? this.width : null,
        height: this.imageStyle !== 'original' ? this.height : null,
        upscale: this.upscale,
      }
    },
    lazyThumbnailSrc() {
      return this.imageUrl({
        crop: this.crop,
        imageStyle: this.imageStyle,
        src: this.src || 'no-image.png',
        width: this.width / 8,
        height: this.height / 8,
        upscale: this.upscale,
      })
    },
    isGif() {
      return this.src?.endsWith('.gif')
    },
    isSvg() {
      return this.src && this.src.includes('.svg')
    },
    svgSource() {
      return this.imageUrl({
        ...this.imageUrlSettings,
      })
    },
    sources() {
      return {
        normal: this.imageUrl({
          ...this.imageUrlSettings,
          pixelRatio: 1,
        }),
        retina: this.imageUrl({
          ...this.imageUrlSettings,
          pixelRatio: 2,
        }),
      }
    },
    sourcesOriginal() {
      return {
        normal: this.imageUrl({
          ...this.imageUrlSettings,
          imageStyle: 'original',
          pixelRatio: 1,
        }),
        retina: this.imageUrl({
          ...this.imageUrlSettings,
          imageStyle: 'original',
          pixelRatio: 2,
        }),
      }
    },
    sourcesWebp() {
      return {
        normal: this.imageUrl({
          ...this.imageUrlSettings,
          pixelRatio: 1,
          webP: true,
        }),
        retina: this.imageUrl({
          ...this.imageUrlSettings,
          pixelRatio: 2,
          webP: true,
        }),
      }
    },
    srcsetBreakpointsSettings() {
      return {
        crop: this.crop,
        src: this.src || 'no-image.png',
        upscale: this.upscale,
      }
    },
  },
}
