import { actions as parentActions } from '~/sites/shop/store/homepage'
import brandFragments from '~/queries/fragments/brand'
import contentBlocksQuery from '~/app/contentBlocksQuery'
import gql from 'graphql-tag'
import processContentBuilder from '@/app/processContentBuilder'

const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_CONFIGURATION({ dispatch }) {
    try {
      const client = this.app.apolloProvider.defaultClient
      const response = await client.query({
        query: gql`
          ${brandFragments.all}
          query {
            configuration_hp_content {
              content
              content_blocks {
                ${contentBlocksQuery()}
              }
              hp_brands {
                entity {
                  ...brandFragmentsAll
                }
              }
              limited_offer_reference {
                entity {
                  ... on limited_offer {
                    _id
                    type
                    title
                    active
                    offer_datetime_from
                    offer_datetime
                    text_button
                    domain_enabled
                    product_reference {
                      entityId
                    }
                  }
                }
              }
            }
          }
        `,
      })
      const data = response.data.configuration_hp_content
      if (data.content) {
        data.content = await processContentBuilder(data.content, dispatch)
      }
      return data
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions }
