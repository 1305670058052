import { getQueryFilter } from '~/queries/plugins'
import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

export const actions = {
  async LOAD_AUTHOR({ dispatch }, { authorSlug }) {
    try {
      const { siteCode } = this.$themeSettings || {}
      const client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode], ['filters.page_meta.slug[0]', JSON.stringify(authorSlug)]],
      })
      const result = await client.query({
        query: gql`
          {
            author${filter} {
              entities {
                _id
                title
                perex
                image {
                  title
                  alt
                  path
                }
                content
                page_meta {
                  seo_description
                  seo_keywords
                  seo_title
                  meta_nofollow
                  meta_noindex
                  slug
                  share_image
                  canonical_slugs {
                    language
                    slug
                  }
                }
              }
            }
          }
        `,
      })
      const author = (result.data.author.entities || [])[0] || {}
      const { content } = author
      if (content) {
        author.content = await processContentBuilder(content, dispatch)
      }
      return author
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_AUTHORS({}, { page, perPage }) {
    try {
      const { siteCode } = this.$themeSettings || {}
      let client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [['filters.site_enabled', siteCode], ['filters.author_has_page', true]],
      })
      const result = await client.query({
        query: gql`
          {
            author${filter} {
              total
              entities {
                _id
                title
                image{
                  alt
                  path
                  title
                }
                content
                author_has_page
                page_meta {
                  slug
                  slug_redirects
                }
              }
            }
          }
        `,
      })
      return result.data.author
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  authors: [],
})

export const mutations = {
  SET(state, { key, value }) {
    state[key] = value
  },
}
