import { get } from 'lodash'
import TopCategory from '~/themes/base/components/category/TopCategory'

export default {
  mixins: [TopCategory],
  methods: {
    getCategoryImage() {
      const imageObject = TopCategory.methods.getCategoryImage.apply(this, arguments)

      if (!imageObject.path) {
        imageObject.path =
          get(this.category, 'top_categories[0].entity.data.content.top_category_image[0].fileKey') || false
      }

      return imageObject
    },
  },
}
