/*
  Documentation url:
  https://sluzby.heureka.cz/napoveda/mereni-konverzi/
*/
export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const heureka_public_key = context.store.state.globals.settings.integrations.heureka_public_key

    window._hrq = window._hrq || []
    window._hrq.push(['setKey', heureka_public_key])
    window._hrq.push(['setOrderId', order.id])
    order.items.forEach(item => {
      window._hrq.push(['addProduct', item.product?.name || item.name, item.prices.unitWithTax, item.amount])
    })
    window._hrq.push(['trackOrder'])

    // Add and execute script
    const ho = document.createElement('script')
    ho.type = 'text/javascript'
    ho.async = true

    if (context.app.i18n.locale === 'sk') {
      ho.src = 'https://im9.cz/sk/js/ext/2-roi-async.js'
    } else {
      ho.src = 'https://im9.cz/js/ext/1-roi-async.js'
    }

    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(ho, s)
  })
}
