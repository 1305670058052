<template>
  <div :class="[getMainClasses]">
    <nuxt/>
    <utils/>
  </div>
</template>

<script>
// import '~/lang/cs' // Preloads global translations
// import '~/lang/sk' // Preloads global translations
import CookiebotMixin from '~/sites/shop/mixins/CookiebotMixin.vue'
import utils from '@site/layouts/_utils'

export default {
  name: 'DefaultLayout',
  components: {
    utils,
  },
  mixins: [CookiebotMixin],
  data() {
    return {
      mainClass: {
        stickyHeader: false,
      },
    }
  },
  computed: {
    getMainClasses() {
      return [this.mainClass.stickyHeader]
    },
  },
  beforeMount() {
    this.$nuxt.$on('mainClassStickyHeader', this.mainClassStickyHeader)
  },
  beforeDestroy() {
    this.$nuxt.$off('mainClassStickyHeader', this.mainClassStickyHeader)
  },
  methods: {
    mainClassStickyHeader(state) {
      const statesArray = ['not_sticky', 'sticky_not_ready', 'sticky_ready', 'sticky_invisible', 'sticky_visible']
      this.mainClass.stickyHeader = `stickyHeader--${statesArray[state]}`
    },
  },
}
</script>
