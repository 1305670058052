import { actions as parentActions } from '~/sites/shop/store/configurator'

const actions = {
  ...parentActions,
  LOAD_CONFIGURATOR() {
    return {}
  },
}

export { actions }
