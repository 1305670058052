import { getters, mutations, actions as parentActions, state as parentState } from '~/sites/shop/store/cart'
import { merge } from 'lodash'

const overloadState = {
  input: {
    complianceHeureka: true,
    complianceZbozi: true,
  },
}

const state = () => ({
  ...merge(parentState(), overloadState),
})

const actions = {
  ...parentActions,
  async FETCH_ORDER_STATUS(_, { code }) {
    return this.$axios.$get(`${this.$env.STORE_URL}/api/v1/orders/code/${code}`, {
      validateStatus(status) {
        return status >= 200 && status < 300
      },
    })
  },
}

export { actions, state, mutations, getters }
