import ProductBoxColorVariants from '~/themes/base/components/product/ProductBoxColorVariants'

export default {
  extends: ProductBoxColorVariants,
  computed: {
    availableAlternativeProducts() {
      return this.alternativeProducts.filter(p => p.availability.isAvailable === true)
    },
  },
}
