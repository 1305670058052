export default ({ eventBus, eventDefinitions, context }) => {
  function updateCart() {
    const cart = context.store.state.cart.cartData
    window.Targito.push('property', 'cart', {
      url: document.location.pathname + document.location.search,
      total: cart.prices.itemsWithoutTax,
      total_vat: cart.prices.itemsWithTax,
      products: cart.items.filter(item => item).map(item => ({
        item_id: item.shoppable.marketingCode.toString(),
        item_price: item.product.prices.unitWithoutTax,
        item_price_vat: item.product.prices.unitWithTax,
        item_quantity: item.amount,
      })),
      attributes: {},
    })
  }
  eventBus.$on(eventDefinitions.CART.ITEM.ADD, () => {
    updateCart()
  })
  eventBus.$on(eventDefinitions.CART.ITEM.REMOVE, () => {
    updateCart()
  })
}
