import gql from 'graphql-tag'
import processContentBuilder from '~/app/processContentBuilder'

export const actions = {
  async LOAD_INFOMAIL({ commit, dispatch }, { slug }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          query infomail($slug: String!) {
            infomail(filters: { page_meta: { slug: [$slug] } }) {
              entities {
                title
                infomail_html
                content
                page_meta {
                  canonical_slugs {
                    language
                    slug
                  }
                  meta_nofollow
                  meta_noindex
                  seo_description
                  seo_keywords
                  seo_title
                  slug
                }
              }
            }
          }
        `,
        variables: {
          slug,
        },
      })
      const page = result.data.infomail.entities[0]
      if (page.content) {
        page.content = await processContentBuilder(page.content, dispatch)
      }
      return page
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
