export default async function({ store, req, app, route }) {
  if (typeof route.query.performance !== 'undefined') {
    return
  }
  if (
    process.server &&
    app.head.script.filter(script => script.src && script.src.indexOf('https://www.googletagmanager.com/gtm.js') === 0)
      .length === 0
  ) {
    const gtmId = store.state.globals.settings.integrations.google_tag_manager
    if (gtmId) {
      const queryParams = {}
      queryParams.id = gtmId

      const queryString = Object.keys(queryParams)
        .filter(key => queryParams[key] !== null && queryParams[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&')

      // Add google tag manager script to head
      app.head.script.push({
        src: 'https://www.googletagmanager.com/gtm.js' + '?' + queryString,
        async: true,
      })
    }
  }
}
