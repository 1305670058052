import { actions as parentActions } from '~/sites/shop/store/index'

const actions = {
  async nuxtServerInit({ commit, dispatch }, { app, req, res, store, route }) {
    if (process.server) {
      console.log(['Original full path', route.fullPath].join(': '))
    }
    await parentActions.nuxtServerInit.apply(this, [{ commit, dispatch }, { app, req, res, store, route }])
  },
}

export { actions }
