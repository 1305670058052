import axios from 'axios'

export const actions = {
  async GET_RECOMMENDATION({ state, rootState }, { placement, context = {} }) {
    if (!this.$contentSettings.events.quarticon.enable) {
      return [] // Fix recommendation if none is enabled on project
    }
    try {
      const widgetId = state.placements[this.$env.SAIMON_PROJECT][placement]
      let url = `${this.$env.SAIMON_GATEWAY_URL}/${
        this.$env.SAIMON_PROJECT
      }/recommender/recommender/recommendation?widgetId=${widgetId}`
      const response = await axios.post(url, {
        context: {
          projectId: this.$env.SAIMON_PROJECT,
          userId: getSaimonUserId(),
          cartProducts:
            rootState.cart.cartData && rootState.cart.cartData.items
              ? rootState.cart.cartData.items.map(item => item.product.marketingCode)
              : null,
          displayedProductId: context.product ? context.product.marketingCode : null,
          displayedCategoryId: context.category
            ? context.category.id
            : context.virtualCategory
              ? context.virtualCategory._id
              : null,
          productId: context.product ? context.product.marketingCode : null,
        },
      })

      return response.data.product_ids.map(item => ({
        code: item,
        recommendation: {
          type: 'saimon',
          widgetId,
          payload: item,
          placement,
          context,
        },
      }))
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  apiKey: '',
  placements: {
    sexshop: {
      category__forYou: '626932c0700fe0339ccf815d',
      addToCart__crossSell: '62693379700fe0339ccf8161',
      checkout__cartItems: '62693355700fe0339ccf8160',
      checkout__emptyCart: '62693265700fe0339ccf815c',
      homepage__forYou: '62693265700fe0339ccf815c',
      notFound__forYou: '62693265700fe0339ccf815c',
      productDetail__related: '6269332a700fe0339ccf815f',
      productDetail__crossSell: '62693314700fe0339ccf815e',
      internalError__forYou: '62693265700fe0339ccf815c',
    },
    intimfitnesscz: {
      category__forYou: '526932c0700fe0339ccf815d',
      addToCart__crossSell: '52693379700fe0339ccf8161',
      checkout__cartItems: '52693355700fe0339ccf8160',
      checkout__emptyCart: '52693265700fe0339ccf815c',
      homepage__forYou: '52693265700fe0339ccf815c',
      notFound__forYou: '52693265700fe0339ccf815c',
      productDetail__related: '5269332a700fe0339ccf815f',
      productDetail__crossSell: '52693314700fe0339ccf815e',
      internalError__forYou: '52693265700fe0339ccf815c',
    },
    erossk: {
      category__forYou: '426932c0700fe0339ccf815d',
      addToCart__crossSell: '42693379700fe0339ccf8161',
      checkout__cartItems: '42693355700fe0339ccf8160',
      checkout__emptyCart: '42693265700fe0339ccf815c',
      homepage__forYou: '42693265700fe0339ccf815c',
      notFound__forYou: '42693265700fe0339ccf815c',
      productDetail__related: '4269332a700fe0339ccf815f',
      productDetail__crossSell: '42693314700fe0339ccf815e',
      internalError__forYou: '42693265700fe0339ccf815c',
    },
    dashsexshopcz: {
      category__forYou: '326932c0700fe0339ccf815d',
      addToCart__crossSell: '32693379700fe0339ccf8161',
      checkout__cartItems: '32693355700fe0339ccf8160',
      checkout__emptyCart: '32693265700fe0339ccf815c',
      homepage__forYou: '32693265700fe0339ccf815c',
      notFound__forYou: '32693265700fe0339ccf815c',
      productDetail__related: '3269332a700fe0339ccf815f',
      productDetail__crossSell: '32693314700fe0339ccf815e',
      internalError__forYou: '32693265700fe0339ccf815c',
    },
  },
})

export function getSaimonUserId() {
  const v = document.cookie.match('(^|;) ?' + 'saimon-user-id' + '=([^;]*)(;|$)')
  if (v) {
    return v[2]
  }
}
