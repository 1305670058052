import { mutations, actions as parentActions, state } from '~/sites/shop/store/category'
import gql from 'graphql-tag'
import processContentBuilder from '@/app/processContentBuilder'
import virtualCategoryFragments from '@/queries/fragments/virtualCategory'

const actions = {
  ...parentActions,
  async LOAD_FILTERED_CATEGORY({ commit, dispatch }, { slugs }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${virtualCategoryFragments.all}
          query filtered_category($slugs: [String]!) {
            filtered_category(filters: { page_meta: { slug: $slugs } }) {
              entities {
                ...virtualCategoryFragmentAll
              }
            }
          }
        `,
        variables: {
          slugs,
        },
      })
      const data = result.data.filtered_category.entities[0]
      if (data) {
        data.content = await processContentBuilder(data.content, dispatch)
        return data
      }
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions, mutations, state }
