import ContentBuilderBlock from '@/mixins/ContentBuilderBlock.vue'

export default {
  name: 'CuratedGuidesBlock',
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'curated_guides',
  },
  data() {
    return {
      guides: [],
    }
  },
  created() {
    this.guides = this.block.curated_guide_reference.map(ref => {
      const guide = { ...ref.entity }
      const titleParts = guide.title.split(':')

      guide.title = titleParts[0].trim()
      guide.subtitle = titleParts[1]?.trim()

      return guide
    })
  },
  query() {
    return `
      ... on block_curated_guides {
        type
        uuid
        curated_guide_reference {
          entityId
          entity {
            ... on curated_guide_page {
              _id
              title
              subtitle
              page_meta {
                slug
              }
            }
          }
        }
      }
    `
  },
}
