import articlePageView from './article.page_view'
import cartUpdate from './cart.update'
import catalogPageView from './catalog.page_view'
import newsletterSubscribed from './newsletter.subscribed'
import productView from './product.page_view'
import purchaseView from './purchase.view'
import userLoaded from './user.loaded'

export default {
  register(eventContext) {
    if (eventContext.context.app.$contentSettings.events.targito.enable) {
      // Add Targito script to body
      ;(function() {
        if (typeof eventContext.context.route.query.performance !== 'undefined') {
          return
        }

        window.Targito = window.Targito || []
        const trg = document.createElement('script')
        trg.type = 'text/javascript'
        trg.async = true
        trg.defer = true
        trg.src = eventContext.context.$env.TARGITO_URL
        trg.setAttribute('data-id', eventContext.context.app.$contentSettings.events?.targito?.id)
        trg.setAttribute('data-origin', eventContext.context.app.$contentSettings.events?.targito?.origin)
        trg.setAttribute('data-domain', eventContext.context.app.$contentSettings.events?.targito?.domain)
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(trg, s)
      })()
      ;[
        articlePageView,
        cartUpdate,
        catalogPageView,
        newsletterSubscribed,
        productView,
        purchaseView,
        userLoaded,
      ].forEach(callback => {
        callback(eventContext)
      })
    }
  },
}
