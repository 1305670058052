import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _79385c35 = () => interopDefault(import('../sites/virtshop/pages/user/activate.vue' /* webpackChunkName: "sites/virtshop/pages/user/activate" */))
const _707f8407 = () => interopDefault(import('../sites/virtshop/pages/authorsList/index.vue' /* webpackChunkName: "sites/virtshop/pages/authorsList/index" */))
const _340f06a4 = () => interopDefault(import('../sites/virtshop/pages/blocksettings.vue' /* webpackChunkName: "sites/virtshop/pages/blocksettings" */))
const _39e37fd2 = () => interopDefault(import('../sites/virtshop/pages/catalogProductCount.vue' /* webpackChunkName: "sites/virtshop/pages/catalogProductCount" */))
const _03b12325 = () => interopDefault(import('../sites/virtshop/pages/promotions/index.vue' /* webpackChunkName: "sites/virtshop/pages/promotions/index" */))
const _2f3b1fe1 = () => interopDefault(import('../sites/virtshop/pages/term/index.vue' /* webpackChunkName: "sites/virtshop/pages/term/index" */))
const _8d2310c6 = () => interopDefault(import('../sites/virtshop/pages/search/index.vue' /* webpackChunkName: "sites/virtshop/pages/search/index" */))
const _7b13661a = () => interopDefault(import('../sites/virtshop/pages/category/index.vue' /* webpackChunkName: "sites/virtshop/pages/category/index" */))
const _2aece1a1 = () => interopDefault(import('../sites/virtshop/pages/contact/index.vue' /* webpackChunkName: "sites/virtshop/pages/contact/index" */))
const _7c25b98a = () => interopDefault(import('../sites/virtshop/pages/checkout/index.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/index" */))
const _fa6b0f96 = () => interopDefault(import('../sites/virtshop/pages/articleList/index.vue' /* webpackChunkName: "sites/virtshop/pages/articleList/index" */))
const _254eb017 = () => interopDefault(import('../sites/virtshop/pages/faq/index.vue' /* webpackChunkName: "sites/virtshop/pages/faq/index" */))
const _d651f1d2 = () => interopDefault(import('../sites/virtshop/pages/branch/index.vue' /* webpackChunkName: "sites/virtshop/pages/branch/index" */))
const _4e3ffa75 = () => interopDefault(import('../sites/virtshop/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/virtshop/pages/shoppingGuide/index" */))
const _f462903a = () => interopDefault(import('../sites/virtshop/pages/compare.vue' /* webpackChunkName: "sites/virtshop/pages/compare" */))
const _10afec32 = () => interopDefault(import('../sites/virtshop/pages/login.vue' /* webpackChunkName: "sites/virtshop/pages/login" */))
const _5b380dae = () => interopDefault(import('../sites/virtshop/pages/curatedGuidePage/index.vue' /* webpackChunkName: "sites/virtshop/pages/curatedGuidePage/index" */))
const _6bad1cd6 = () => interopDefault(import('../sites/virtshop/pages/register.vue' /* webpackChunkName: "sites/virtshop/pages/register" */))
const _19edc6ec = () => interopDefault(import('../sites/virtshop/pages/complaint/index.vue' /* webpackChunkName: "sites/virtshop/pages/complaint/index" */))
const _32343272 = () => interopDefault(import('../sites/virtshop/pages/passwordReset.vue' /* webpackChunkName: "sites/virtshop/pages/passwordReset" */))
const _1ea951b5 = () => interopDefault(import('../sites/virtshop/pages/sitemapconfig.vue' /* webpackChunkName: "sites/virtshop/pages/sitemapconfig" */))
const _7e1c3b61 = () => interopDefault(import('../sites/virtshop/pages/orderStatus/index.vue' /* webpackChunkName: "sites/virtshop/pages/orderStatus/index" */))
const _66ee781c = () => interopDefault(import('../sites/virtshop/pages/fittingGuide/index.vue' /* webpackChunkName: "sites/virtshop/pages/fittingGuide/index" */))
const _34cbc411 = () => interopDefault(import('../sites/virtshop/pages/maintenance.vue' /* webpackChunkName: "sites/virtshop/pages/maintenance" */))
const _be954700 = () => interopDefault(import('../sites/virtshop/pages/user/index.vue' /* webpackChunkName: "sites/virtshop/pages/user/index" */))
const _428cab7c = () => interopDefault(import('../sites/virtshop/pages/complaintReturn/index.vue' /* webpackChunkName: "sites/virtshop/pages/complaintReturn/index" */))
const _89aa21a2 = () => interopDefault(import('../sites/virtshop/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/virtshop/pages/aboutPurchase/index" */))
const _2f43a03d = () => interopDefault(import('../sites/virtshop/pages/passwordForgotten.vue' /* webpackChunkName: "sites/virtshop/pages/passwordForgotten" */))
const _62c795c8 = () => interopDefault(import('../sites/virtshop/pages/brand/index.vue' /* webpackChunkName: "sites/virtshop/pages/brand/index" */))
const _e2b85c8e = () => interopDefault(import('../sites/virtshop/pages/auth/proxy.vue' /* webpackChunkName: "sites/virtshop/pages/auth/proxy" */))
const _64ee3155 = () => interopDefault(import('../sites/virtshop/pages/checkout/info.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/info" */))
const _1e14eb99 = () => interopDefault(import('../sites/virtshop/pages/checkout/finished.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/finished" */))
const _9846344a = () => interopDefault(import('../sites/virtshop/pages/checkout/methods.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/methods" */))
const _958815f0 = () => interopDefault(import('../sites/virtshop/pages/checkout/bulkOrder/index.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/bulkOrder/index" */))
const _182559d5 = () => interopDefault(import('../sites/virtshop/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/importOrder/index" */))
const _d4a19e8e = () => interopDefault(import('../sites/virtshop/pages/articleTag/index.vue' /* webpackChunkName: "sites/virtshop/pages/articleTag/index" */))
const _55c98d04 = () => interopDefault(import('../sites/virtshop/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/virtshop/pages/user/shoppingLists/index" */))
const _29f3de2c = () => interopDefault(import('../sites/virtshop/pages/user/orders/index.vue' /* webpackChunkName: "sites/virtshop/pages/user/orders/index" */))
const _0a43462c = () => interopDefault(import('../sites/virtshop/pages/user/favourites.vue' /* webpackChunkName: "sites/virtshop/pages/user/favourites" */))
const _3f6fa936 = () => interopDefault(import('../sites/virtshop/pages/user/settings.vue' /* webpackChunkName: "sites/virtshop/pages/user/settings" */))
const _25b37242 = () => interopDefault(import('../sites/virtshop/pages/user/dashboard.vue' /* webpackChunkName: "sites/virtshop/pages/user/dashboard" */))
const _fdbafc40 = () => interopDefault(import('../sites/virtshop/pages/user/addressList.vue' /* webpackChunkName: "sites/virtshop/pages/user/addressList" */))
const _3b89a446 = () => interopDefault(import('../sites/virtshop/pages/user/password.vue' /* webpackChunkName: "sites/virtshop/pages/user/password" */))
const _5943d0e3 = () => interopDefault(import('../sites/virtshop/pages/summerContest.vue' /* webpackChunkName: "sites/virtshop/pages/summerContest" */))
const _4c4d9b5c = () => interopDefault(import('../sites/virtshop/pages/easter.vue' /* webpackChunkName: "sites/virtshop/pages/easter" */))
const _f905b756 = () => interopDefault(import('../sites/virtshop/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/virtshop/pages/checkout/payment/callback" */))
const _2e0066d0 = () => interopDefault(import('../sites/virtshop/pages/index.vue' /* webpackChunkName: "sites/virtshop/pages/index" */))
const _9ef4f934 = () => interopDefault(import('../sites/virtshop/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/virtshop/pages/user/shoppingLists/_id" */))
const _53d745d2 = () => interopDefault(import('../sites/virtshop/pages/user/orders/_id.vue' /* webpackChunkName: "sites/virtshop/pages/user/orders/_id" */))
const _18c75278 = () => interopDefault(import('../sites/virtshop/pages/author/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/author/_slug" */))
const _2d847e99 = () => interopDefault(import('../sites/virtshop/pages/term/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/term/_slug" */))
const _0c8fbd52 = () => interopDefault(import('../sites/virtshop/pages/infomail/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/infomail/_slug" */))
const _07d954a2 = () => interopDefault(import('../sites/virtshop/pages/article/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/article/_slug" */))
const _ad6690a0 = () => interopDefault(import('../sites/virtshop/pages/articleList/_slug/index.vue' /* webpackChunkName: "sites/virtshop/pages/articleList/_slug/index" */))
const _d9bf3462 = () => interopDefault(import('../sites/virtshop/pages/branch/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/branch/_slug" */))
const _76711b30 = () => interopDefault(import('../sites/virtshop/pages/product/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/product/_slug" */))
const _59816c66 = () => interopDefault(import('../sites/virtshop/pages/curatedGuidePage/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/curatedGuidePage/_slug" */))
const _7fe6ec5b = () => interopDefault(import('../sites/virtshop/pages/orderStatus/_code.vue' /* webpackChunkName: "sites/virtshop/pages/orderStatus/_code" */))
const _d80ee11e = () => interopDefault(import('../sites/virtshop/pages/articleTag/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/articleTag/_slug" */))
const _1f8ce608 = () => interopDefault(import('../sites/virtshop/pages/page/_slug.vue' /* webpackChunkName: "sites/virtshop/pages/page/_slug" */))
const _101270a2 = () => interopDefault(import('../sites/virtshop/pages/articleList/_slug/_child.vue' /* webpackChunkName: "sites/virtshop/pages/articleList/_slug/_child" */))
const _6af2d2dd = () => interopDefault(import('../sites/virtshop/pages/_.vue' /* webpackChunkName: "sites/virtshop/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _79385c35,
    name: "user-activate___cs"
  }, {
    path: "/autori",
    component: _707f8407,
    name: "authorsList___cs"
  }, {
    path: "/blocksettings",
    component: _340f06a4,
    name: "blocksettings___cs"
  }, {
    path: "/catalogProductCount",
    component: _39e37fd2,
    name: "catalogProductCount___cs"
  }, {
    path: "/eroticke-pomucky-v-akci-na-sexshop-cz",
    component: _03b12325,
    name: "promotions___cs"
  }, {
    path: "/eroticky-vykladovy-slovnik",
    component: _2f3b1fe1,
    name: "term___cs"
  }, {
    path: "/hledat",
    component: _8d2310c6,
    name: "search___cs"
  }, {
    path: "/kategorie",
    component: _7b13661a,
    name: "category___cs"
  }, {
    path: "/kontakt",
    component: _2aece1a1,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _7c25b98a,
    name: "checkout___cs"
  }, {
    path: "/nakupni-radce",
    component: _fa6b0f96,
    name: "articleList___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _254eb017,
    name: "faq___cs"
  }, {
    path: "/pobocky",
    component: _d651f1d2,
    name: "branch___cs"
  }, {
    path: "/poradna",
    component: _4e3ffa75,
    name: "shoppingGuide___cs"
  }, {
    path: "/porovnat",
    component: _f462903a,
    name: "compare___cs"
  }, {
    path: "/prihlasit",
    component: _10afec32,
    name: "login___cs"
  }, {
    path: "/pruvodce",
    component: _5b380dae,
    name: "curatedGuidePage___cs"
  }, {
    path: "/registrovat",
    component: _6bad1cd6,
    name: "register___cs"
  }, {
    path: "/reklamace",
    component: _19edc6ec,
    name: "complaint___cs"
  }, {
    path: "/resetovat-heslo",
    component: _32343272,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _1ea951b5,
    name: "sitemapconfig___cs"
  }, {
    path: "/stav-objednavky",
    component: _7e1c3b61,
    name: "orderStatus___cs"
  }, {
    path: "/tabulka-velikosti",
    component: _66ee781c,
    name: "fittingGuide___cs"
  }, {
    path: "/udrzba",
    component: _34cbc411,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _be954700,
    name: "user___cs"
  }, {
    path: "/vraceni-zbozi",
    component: _428cab7c,
    name: "complaintReturn___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _89aa21a2,
    name: "aboutPurchase___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _2f43a03d,
    name: "passwordForgotten___cs"
  }, {
    path: "/znacky",
    component: _62c795c8,
    name: "brand___cs"
  }, {
    path: "/auth/proxy",
    component: _e2b85c8e,
    name: "auth-proxy___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _64ee3155,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _1e14eb99,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _9846344a,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/hromadna-objednavka",
    component: _958815f0,
    name: "checkout-bulkOrder___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _182559d5,
    name: "checkout-importOrder___cs"
  }, {
    path: "/tema/clanky",
    component: _d4a19e8e,
    name: "articleTag___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _55c98d04,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _29f3de2c,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/oblibene",
    component: _0a43462c,
    name: "user-favourites___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _3f6fa936,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _25b37242,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _fdbafc40,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _3b89a446,
    name: "user-password___cs"
  }, {
    path: "/vse-o-nakupu/soutez-o-balicek-hracek-na-leto",
    component: _5943d0e3,
    name: "summerContest___cs"
  }, {
    path: "/vse-o-nakupu/velikonocni-soutez",
    component: _4c4d9b5c,
    name: "easter___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _f905b756,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _2e0066d0,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _9ef4f934,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _53d745d2,
    name: "user-orders-id___cs"
  }, {
    path: "/autori/:slug",
    component: _18c75278,
    name: "author-slug___cs"
  }, {
    path: "/eroticky-vykladovy-slovnik/:slug",
    component: _2d847e99,
    name: "term-slug___cs"
  }, {
    path: "/infomail/:slug?",
    component: _0c8fbd52,
    name: "infomail-slug___cs"
  }, {
    path: "/nakupni-radce/:slug",
    component: _07d954a2,
    name: "article-slug___cs"
  }, {
    path: "/nakupni-radce/:slug",
    component: _ad6690a0,
    name: "articleList-slug___cs"
  }, {
    path: "/pobocky/:slug",
    component: _d9bf3462,
    name: "branch-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _76711b30,
    name: "product-slug___cs"
  }, {
    path: "/pruvodce/:slug",
    component: _59816c66,
    name: "curatedGuidePage-slug___cs"
  }, {
    path: "/stav-objednavky/:code",
    component: _7fe6ec5b,
    name: "orderStatus-code___cs"
  }, {
    path: "/tema/:slug",
    component: _d80ee11e,
    name: "articleTag-slug___cs"
  }, {
    path: "/vse-o-nakupu/:slug",
    component: _1f8ce608,
    name: "page-slug___cs"
  }, {
    path: "/nakupni-radce/:slug/:child",
    component: _101270a2,
    name: "articleList-slug-child___cs"
  }, {
    path: "/*",
    component: _6af2d2dd,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
