import { getQueryFilter } from '~/queries/plugins'
import gql from 'graphql-tag'

export const actions = {
  async LOAD_CURATED_GUIDES({}, { page, perPage }) {
    try {
      let { siteCode } = this.$themeSettings || {}

      if (siteCode) {
        siteCode = `"${siteCode}"`
      }

      let client = this.app.apolloProvider.defaultClient
      const filter = getQueryFilter({
        options: [
          ['skip', page * perPage],
          ['limit', perPage],
          ['filters.site_enabled', siteCode],
          ['filters.list_novisible', false],
        ],
      })
      const result = await client.query({
        query: gql`
          {
            curated_guide_page${filter} {
              total
              entities {
                _id
                title
                subtitle
                main_image {
                  path
                  alt
                }
                page_meta {
                  canonical_slugs {
                    language
                    slug
                  }
                  meta_nofollow
                  meta_noindex
                  seo_description
                  seo_keywords
                  seo_title
                  slug
                  share_image
                }
              }
            }
          }
        `,
      })
      return result.data.curated_guide_page
    } catch (e) {
      console.error(e)
    }
  },
}
