export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.NEWSLETTER.SUBSCRIBED, ({ email, source } = {}) => {
    if (source === 'welcome') {
      const data = {
        accountId: context.app.$contentSettings.events.targito.id,
        email: email,
        origin: context.app.$contentSettings.events.targito.origin,
        isOptedIn: false,
        forceDoubleReOptIn: true,
        columns: {
          gdpr_marketing_parent: 'welcome',
          gdpr_marketing_date: new Date()
            .toISOString()
            .substr(0, 19)
            .replace('T', ' '),
        },
      }
      context.app.$axios.$post('https://api.targito.com/v1.0/contacts/AddContact', data, {
        auth: {
          username: context.app.$contentSettings.events.targito.id,
          password: context.app.$contentSettings.events.targito.password,
        },
      })
    }
  })
}
