import pageView from './page.view'
import purchaseView from './purchase.view'

export default {
  register(eventContext) {
    ;[pageView, purchaseView].forEach(callback => {
      callback(eventContext)
    })
  },
}
