import BenefitBodyBlock from '@theme/blocks/BenefitBodyBlock'
import BigTitleBlock from '@theme/blocks/BigTitleBlock'
import CategoriesBlock from '@theme/blocks/CategoriesBlock'
import CollapsibleBlock from '@theme/blocks/CollapsibleBlock'
import CuratedGuideProductsBlock from '@theme/blocks/CuratedGuideProductsBlock'
import CuratedGuidesBlock from '@theme/blocks/CuratedGuidesBlock'
import NewsletterBlock from '@theme/blocks/NewsletterBlock'
import ProductBlock from '@theme/blocks/ProductsBlock'
import QuoteBlock from '@theme/blocks/QuoteBlock'
import TextBlock from '@theme/blocks/TextBlock'
import TextBoxesBlock from '@theme/blocks/TextBoxesBlock'
import UspBlock from '@theme/blocks/UspBlock'

export default {
  BenefitBodyBlock,
  BigTitleBlock,
  CategoriesBlock,
  CollapsibleBlock,
  CuratedGuidesBlock,
  CuratedGuideProductsBlock,
  NewsletterBlock,
  ProductBlock,
  QuoteBlock,
  TextBlock,
  TextBoxesBlock,
  UspBlock,
}
