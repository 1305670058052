export default ({ app, error, route, redirect }) => {
  app.$pageNotFound = async ({ type, slug, path }) => {
    const response = await app.$axios.$get(`${app.$env.CMS_URL}/redirect`, {
      params: {
        route: route.path,
        path: path || route.path,
        slug,
        type,
      },
      validateStatus: false,
    })
    if (response && response.redirectPath && response.redirectPath !== (path || route.path)) {
      let redirectPath = response.redirectPath
      const defaultLocalePath = `/${app.i18n.defaultLocale}/`

      if (redirectPath.startsWith(defaultLocalePath)) {
        redirectPath = redirectPath.replace(defaultLocalePath, '/')
      }

      return redirect(301, redirectPath)
    } else {
      return error({ statusCode: 404 })
    }
  }
}
