export default function({ redirect, route }) {
  if (Object.keys(route.query).includes('hrk')) {
    let query = Object.assign({}, route.query)
    delete query.hrk
    const queryArray = []
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        queryArray.push(key + '=' + query[key])
      }
    }
    let resultQueryString = queryArray.join('&')
    resultQueryString = resultQueryString ? `?${resultQueryString}` : ''
    encodeURI(resultQueryString)
    return redirect(301, route.path + resultQueryString)
  }
}
