import { defaultCountry } from '@site/settings'
import DeliveryDate from '~/themes/base/components/shop/DeliveryDate'

export default {
  extends: DeliveryDate,
  data() {
    return {
      holidaysConstructor: null,
    }
  },
  computed: {
    hasHolidaysInitialized() {
      return this.holidaysConstructor !== null
    },
    cartDeliveryDate() {
      let deliveryDate = new Date()
      const items = this.$store.state.cart.cartData.items
      items.forEach(item => {
        item.shoppable.warehouses.forEach(warehouse => {
          if (warehouse.id === this.branch.warehouseId) {
            const computedDeliveryDate = this.getDeliveryDate(item.variant)

            if (warehouse.stock < item.amount || computedDeliveryDate > deliveryDate) {
              deliveryDate = computedDeliveryDate
            }
          }
        })
      })

      const momentDelivery = this.$moment(deliveryDate)
      while (momentDelivery.weekday() > 4) {
        momentDelivery.add(1, 'day')
      }

      return momentDelivery.toDate()
    },
  },
  async created() {
    this.holidaysConstructor = (await import('date-holidays')).default
  },
  methods: {
    getDeliveryDate(product) {
      const shipperNearestDeliveryDate = this.shipper
        ? new Date(this.shipper?.delivery.nearestDeliveryDate)
        : new Date()
      const productDeliveryDelay = product && product.availability && product.availability.delayDays
      const productDeliveryDate = new Date()

      // TODO: refactor
      // DELIVERY_DAYS = diff between shipperNearestDeliveryDate and today
      if (productDeliveryDelay > 0) {
        productDeliveryDate.setDate(productDeliveryDate.getDate() + productDeliveryDelay)

        if (this.shipper.delivery?.closesAt) {
          const delay = this.parseClosesAtToMs(this.shipper.delivery.closesAt)
          const todayLatest = new Date()
          todayLatest.setHours(0, 0, 0, 0)
          todayLatest.setMilliseconds(todayLatest.getMilliseconds() + delay)

          if (new Date() > todayLatest) {
            productDeliveryDate.setDate(productDeliveryDate.getDate() + 1)
          }
        }

        const deliveryDay = productDeliveryDate.getDay() || DAYS.SUNDAY
        const deliveryDelay = deliveryDay >= DAYS.SATURDAY ? 1 + DAYS.SUNDAY - deliveryDay : 0
        productDeliveryDate.setDate(productDeliveryDate.getDate() + deliveryDelay)

        if (this.hasHolidaysInitialized && defaultCountry) {
          const holidays = new this.holidaysConstructor(defaultCountry)
          let activeHolidays = holidays.isHoliday(productDeliveryDate)

          /** Iterate through holidays. */
          while (activeHolidays !== false) {
            productDeliveryDate.setDate(productDeliveryDate.getDate() + 1)
            activeHolidays = holidays.isHoliday(productDeliveryDate)
          }
        }
      }

      return Math.max(productDeliveryDate, shipperNearestDeliveryDate)
    },
  },
}
