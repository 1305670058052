import contentSettings from '@site/content.settings'

export default function({ app }) {
  const { cookieBot } = contentSettings

  if (cookieBot.cbid) {
    app.head.script.push({
      src: 'https://consent.cookiebot.com/uc.js',
      async: false,
      ['data-cbid']: cookieBot.cbid,
      ['data-blockingmode']: 'manual',
      type: 'text/javascript',
      id: 'Cookiebot',
    })
  }
}
