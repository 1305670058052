export default ({ eventBus, eventDefinitions, context }) => {
  let affiliateIframeSrc = 'affiliate.sexshop.cz'
  let affiliateOldSrc = 'https://affiliate.sexshop.cz/t3.js'
  let affiliateSrc = 'https://affiliate.sexshop.cz/t3.js'
  let abInstance = 'affiliate.sexshop.cz'
  let abInzerent = 3
  let abKampan = 3

  if (context.app.$env.SITE === 'erossk') {
    affiliateIframeSrc = 'affiliate.sexshop.cz'
    affiliateOldSrc = 'https://affiliate.sexshop.cz/js/module/tracking-v3.js'
    affiliateSrc = 'https://affiliate.sexshop.cz/t3.js'
    abInstance = 'affiliate.eros.sk'
    abInzerent = 3
    abKampan = 2
  }
  if (context.app.$env.SITE === 'intimfitnesscz') {
    affiliateIframeSrc = 'partneri.intimfitness.cz'
    affiliateOldSrc = 'https://partneri.intimfitness.cz/t3.js'
    affiliateSrc = 'https://partneri.intimfitness.cz/t3.js'
    abInstance = 'partneri.intimfitness.cz'
    abInzerent = 3
    abKampan = 6
  }

  eventBus.$on(eventDefinitions.SITE.PAGE_VIEW, () => {
    window.ab_instance = abInstance
    window.ab_inzerent = abInzerent
    window.ab_kampan = abKampan

    // Cleanup stuff from previous page view
    const oldScript = document.querySelector(`script[src="${affiliateOldSrc}"]`)
    if (oldScript) {
      oldScript.parentNode.removeChild(oldScript)
    }
    const oldIframe = document.querySelector(`iframe[src*="${affiliateIframeSrc}"]`)
    if (oldIframe) {
      oldIframe.parentNode.removeChild(oldIframe)
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = affiliateSrc
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(script, s)
  })
}
