import { get } from 'lodash'

export const actions = {
  FETCH_CONFIG({ commit, state }) {
    try {
      const config = window.Foxentry.getConfig()
      commit('SET_CONFIG', Object.freeze(config))
      const getInputs = fields => {
        let output = {}
        for (const field of fields) {
          const { data } = field
          for (const item of data) {
            const inputs = get(item, 'mappings.inputs')
            for (const input of inputs) {
              const { elementCode, dataType, dataTypeOut, selector } = input
              const name = selector.match("'(.*)?'")[1]
              if (name) {
                // Push next if already exist
                if (output[name]) {
                  output[name].dataType.push(dataType)
                  output[name].dataTypeOut.push(dataTypeOut)
                } else {
                  // Create new if not define yet
                  output[name] = {
                    elementCode,
                    dataType: [dataType],
                    dataTypeOut: [dataTypeOut],
                    selector,
                  }
                }
              }
            }
          }
        }
        return output
      }
      const { address, company, email, name, phone } = config || {}
      const inputs = {
        ...getInputs([address, company, email, name, phone]),
      }
      commit('SET_INPUTS', inputs)
    } catch (e) {
      console.error(e)
    }
  },
  async FORM_VALIDATION({ state }, { form }) {
    try {
      return await window.Foxentry.formValidation(form)
    } catch (e) {
      console.error(e)
    }
  },
  async FORM_IS_VALID({ state }, { form }) {
    try {
      return await window.Foxentry.isFormValid(form)
    } catch (e) {
      console.error(e)
    }
  },
  async REBUILD() {
    try {
      if (window.Foxentry) {
        window.Foxentry.deactivate()
        window.Foxentry.activate()
      }
      return
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  config: {},
  configMode: false,
  enable: false,
  inputs: {},
  route: false,
})

export const mutations = {
  SET_CONFIG(state, data) {
    if (data) {
      state.config = data
    }
  },
  SET_INPUTS(state, data) {
    if (data) {
      state.inputs = data
    }
  },
  SET_IS_CONFIG_MODE(state, data) {
    state.configMode = data
  },
  SET_IS_ENABLE(state, data) {
    state.enable = data
  },
  SET_ROUTE(state, data) {
    state.route = data
  },
}

export const getters = {
  GET_IS_CONFIG_MODE(state) {
    return state.configMode
  },
  GET_IS_ENABLE(state) {
    return state.enable
  },
  GET_ROUTE(state) {
    return state.route
  },
}
