// Swap with index.js ones all events are moved to here

import affillBox from './affilBox/index'
import dataLayerBasic from './dataLayerBasic/newIndex'
import enhancedEcommerce from './enhancedEcommerce/newIndex'
import heurekaConversions from './heureka/conversions'
import heurekaShopCertification from './heureka/shopCertification'
import targito from './targito/index'
import zbozicz from './zbozicz'

const events = [
  affillBox,
  dataLayerBasic,
  enhancedEcommerce,
  heurekaConversions,
  heurekaShopCertification,
  zbozicz,
  targito,
]
export default events
