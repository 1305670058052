export default {
  ARTICLE: {
    PAGE_VIEW: 'article.page_view',
  },
  CART: {
    ITEM: {
      ADD: 'cart.item.add',
      REMOVE: 'cart.item.remove',
    },
    LOADED: 'cart.loaded',
    PAGE_VIEW: 'cart.page_view',
  },
  CATALOG: {
    PAGE_VIEW: 'catalog.page_view',
  },
  CHECKOUT: {
    STEP_LOAD: 'checkout.step_load',
    STEP_OPTION: 'checkout.step_option',
  },
  NEWSLETTER: {
    SUBSCRIBED: 'newsletter.subscribed',
  },
  NOT_FOUND: {
    PAGE_VIEW: 'not_found.page_view',
  },
  PRODUCT: {
    CLICK: 'product.click',
    IMPRESSION: 'product.impression',
    PAGE_VIEW: 'product.page_view',
  },
  PROMOTION: {
    CLICK: 'promotion.click',
    IMPRESSION: 'promotion.impression',
  },
  ORDER: {
    PLACED: 'order.placed',
  },
  SEARCH: {
    QUERY: 'search.query',
    PAGE_VIEW: 'search.page_view',
  },
  SITE: {
    PAGE_VIEW: 'site.page_view',
    SESSION_LOADED: 'site.session_loaded',
  },
  USER: {
    LOADED: 'user.loaded',
    REGISTERED: 'user.registered',
  },
}
