import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  cookieBot: {
    cbid: '8cd90439-8b0b-4e68-887a-4a09b392b628',
  },
  events: {
    dataLayerBasic: {
      searchQuery: {
        minimumCharacters: 4,
      },
    },
    heureka: {
      conversions: {
        enable: true,
      },
    },
    quarticon: {
      enable: true,
    },
    targito: {
      enable: true,
      domain: 'targito.sexshop.cz',
      id: '24e0592d-f290-49c0-890b-e2aa94ce52ef',
      origin: 'sexshop_cz',
      password: 'cl2o!e72RD&02QDT',
    },
  },
  functions: {
    favourites: true, // WishList must be allowed too
    ssrPropertyPicker: true,
    wishList: true,
  },
  foxentry: {
    id: 'buk2ZSw4fJ', // Set foxentry ID
    routes: ['RouteCheckoutInfo', 'RouteRegister'], // Set allowed routes for foxentry
  },
  googleMerchant: {
    marchantId: '9317939',
  },
  product: {
    manuals: true,
  },
  infoBoxes: true,
  utm: {
    enable: true,
    cookie: {
      enable: true,
    },
  },
  vivTracking: {
    enable: true,
  },
}

export default merge(contentSettings, overloadSettings)
