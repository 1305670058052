export default function(to, from, savedPosition) {
  // Disable scroll in catalog important SEO filters
  if (to.name.match(/^slug_/) && from.name.match(/^slug_/)) {
    return
  }
  /* eslint-disable */
  // Use meta data on pages that have corrupted savedPosition (related to pagination)
  const nuxt = window.$nuxt
  const metaScrollPos = nuxt.context.route.meta[0]?.scrollPos
  /* eslint-enable */

  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (to.matched.length < 2 && to.matched.every(r => r.components.default.options.scrollToTop !== false)) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // Use position only when using popstate navigation
  if (savedPosition) {
    if (from.name === 'all___cs' && to.name === 'all___cs') {
      position = savedPosition
    } else {
      position = metaScrollPos || savedPosition
    }
  }

  return new Promise(resolve => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn(
            'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).',
          )
        }
      }
      resolve(position)
    })
  })
}
