import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  catalog: {
    defaultSortType: 'DefaultSort',
  },
  global: {
    holidayDays: [[1, 1], [1, 5], [5, 7], [6, 7], [28, 9], [28, 10], [17, 11], [24, 12], [25, 12], [26, 12]],
    searchInContent: true,
    showProductsRecommended: true,
    userFullName: false,
  },
  product: {
    defaultWarehouseShipperCode: 'osobni',
    shipperListHiddenShipperCodes: ['osobni'], // Hides selected shippers from the list, used in combination with warehouses
    price: {
      other: {
        withTax: true,
      },
      original: {
        withTax: true,
      },
      primary: {
        label: false,
        show: true,
        withTax: true,
      },
      secondary: {
        label: false,
        show: false,
        withTax: false,
      },
      totalPrimary: {
        label: false,
        show: true,
        withTax: true,
      },
      totalSecondary: {
        label: true,
        show: false,
        withTax: false,
      },
      summaryTotalPrimary: {
        label: false,
        show: true,
        withTax: true,
      },
      summaryTotalSecondary: {
        label: true,
        show: true,
        withTax: false,
      },
    },
    tagMultipleVisibilityOptions: true,
    tagFreeShippers: 'blue',
  },
  components: {
    ArticleBox: {
      articleInfo: {
        author: {
          showLink: true,
          image: {
            width: 25,
            height: 25,
            enable: true,
          },
        },
        date: {
          enable: false,
        },
        enable: true,
      },
      image: {
        crop: true,
        enable: true,
        height: 220, // Change SASSs variable $ArticleBox-image-height
        position: 'top', // top, left
        width: 385, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'link', // link, primary or secondary
        enable: false,
      },
      perex: {
        enable: true,
      },
      showIcons: true,
    },
    ArticleList: {
      perLine: {
        xs: 2,
        lg: 3,
      },
    },
    AuthorsList: {
      perLine: {
        xs: 1,
        lg: 2,
      },
      perPage: 15,
    },
    BranchLine: {
      address: {
        postcode: false,
        country: false,
        openingHours: true,
      },
      modalDetail: {
        enable: true,
      },
    },
    BranchSelectorInline: {
      showDeliveryDate: false,
    },
    BranchSelectorPPL: {
      countriesAvailable: 'cz',
      hiddenPoints: 'AlzaBox',
    },
    CatalogFiltersMobile: {
      button: 'primary btn-block', // primary, secondary, cta
    },
    CatalogSorting: {
      options: ['DefaultSort', 'LatestSort', 'PriceSortASC', 'PriceSortDESC'], // DefaultSort, MostSoldSort, PriceSortASC, PriceSortDESC, NameSortASC, NameSortDESC
    },
    CheckoutItemLine: {
      showDeliveryDate: false,
    },
    CheckoutRecapBox: {
      disableDiscountProductImage: false,
    },
    CookieConsent: {
      enable: false,
    },
    CuratedGuideList: {
      perLine: {
        xs: 2,
        lg: 3,
      },
    },
    EntryModal: {
      enable: true,
    },
    FrequentQuestionBox: {
      enableAccordion: true,
      showFaqCategoryIcon: true,
    },
    FittingGuideModal: {
      modalWindowMaxWidth: 'lg',
    },
    FooterBottom: {
      paymentMethods: {
        enable: true,
        items: [],
      },
    },
    HeaderSearch: {
      close: {
        enable: false,
      },
    },
    HeaderTop: {
      loginLink: {
        enable: true,
        type: 'dropdown', // link, dropdown, modal
      },
      showLanguageSelect: false,
      showRegistrationLink: true,
    },
    JavascriptRequired: {
      enable: false,
    },
    MainMenu: {
      linkStyles: ['stretched'],
      showDropdownIndicator: false,
    },
    OrderBox: {
      showItemsAmount: true,
    },
    PageArticleDetail: {
      perex: {
        enable: false,
      },
      sidebar: {
        enable: false,
      },
      articleInfo: {
        author: {
          image: {
            enable: true,
          },
          showLink: true,
        },
      },
    },
    PageArticleList: {
      sidebar: {
        enable: false,
      },
    },
    PageAuthorsList: {
      header: {
        enable: false,
      },
      menuHorizontal: {
        enable: false, // Requires header enabled!
      },
      sidebar: {
        enable: false,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleListAuthor: {
      sidebar: {
        enable: false,
      },
    },
    PageArticleListCategory: {
      sidebar: {
        enable: false,
      },
    },
    PageBrandList: {
      brandType: 'BrandBox',
      showLetters: false,
      showAll: false,
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: [],
      },
      subCategories: {
        showInImportantFilteredCategory: false,
      },
    },
    PageCheckoutFinished: {
      googleSurveyEnabled: false,
    },
    PageCheckoutInfo: {
      billingTitleAlternativePosition: true,
      disableHeurekaCheckbox: true,
      input: {
        billingHouseNumber: {
          enable: true,
        },
        dateOfBirth: {
          enable: true,
        },
        houseNumber: {
          enable: true,
        },
        shippingBusinessName: {
          enable: true,
          enableWithBusiness: true,
          enableOnlyInShippingInfo: true,
        },
      },
      loginLink: {
        type: 'dropdown',
      },
      newsletter: {
        defaultValue: false,
        showCheckbox: true,
        showCheckboxForLoggedUser: true,
      },
      openTextPageLinksInModal: true,
      orderPlaced: {
        parameters: {
          fetch: true,
          selectedParameters: ['5cb738b2e5cc4c0017ea7f53'],
        },
      },
    },
    PageCheckoutMethods: {
      enableInsurance: false,
    },
    PageContact: {
      showBranches: true,
      showPersonalContacts: false,
    },
    PageCuratedGuideList: {
      header: {
        enable: false,
      },
      menuHorizontal: {
        enable: false,
      },
      perPage: 15,
      sidebar: {
        enable: false,
      },
    },
    PageFaq: {
      categories: {
        showSidebar: false,
        showIconBoxes: true,
      },
    },
    PageFittingGuide: {
      showUniqueSellingPoints: true,
    },
    PageHomepage: {
      showHomepageArticles: true,
      showHomepageIntimateAdvisory: true,
      showHomepageTextBlock: true,
      showNewestProducts: true,
      showTopCategories: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageInternalError: {
      defaultImage: false,
    },
    PageNotFound: {
      defaultImage: false,
    },
    PageProductDetail: {
      badgeWidgetsInSidebar: {
        enableOn: [],
      },
      recommendedProducts: {
        forceEnable: true,
        useCategoryProductsFallback: true,
      },
      requireSelectedVariantToAddToCart: true,
      reviews: {
        enable: true,
      },
      selectDefaultVariant: false,
      showAvailabilityInWarehouses: true,
      showColorVariants: true,
      showProductComments: false,
    },
    PageRegister: {
      houseNumber: {
        enable: true,
      },
      newsletter: {
        defaultValue: true,
        showCheckbox: false,
      },
    },
    PageUserSettings: {
      newsletter: {
        showCheckbox: true,
      },
    },
    ProductAvailability: {
      showTextsFromBackend: true,
    },
    ProductBox: {
      badge: {
        color: 'red',
      },
      showColorVariants: true,
      showDateOfStorage: true,
      showStarRating: true,
      showVariantsAsLink: true,
    },
    ProductDateOfStorage: {
      dropdown: {
        enable: false,
      },
      showAmount: false,
    },
    ProductTeaser: {
      showDateOfStorage: true,
    },
    RatingTable: {
      enable: true,
    },
    SearchInput: {
      button: {
        show: true,
      },
    },
    StarRating: {
      activeColor: '#ff7a79',
      inactiveColor: '#dee2e6',
    },
    UniqueSellingPoints: {
      itemClass: 'col-12 col-md-12 col-xl-6 px-1 py-1 p-md-2',
    },
  },
  layouts: {
    LayoutCheckout: {
      showFooterBottom: false,
    },
  },
}

export default merge(themeSettings, overloadSettings)
