export default ({ eventBus, eventDefinitions }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const items = order.items.filter(cartItem => !(cartItem.attributes && cartItem.attributes.discount))
    window.Targito.push('event', 'order', {
      order_id: order.id.toString(),
      total: order.prices.itemsWithoutTax,
      total_vat: order.prices.itemsWithTax,
      products: items.filter(item => item && item.shoppable).map(item => ({
        item_id: item.shoppable.marketingCode.toString(),
        item_price: item.product.prices.unitWithoutTax,
        item_price_vat: item.product.prices.unitWithTax,
        item_quantity: item.amount,
      })),
      attributes: {
        billing_full_name: order.customer.name,
        billing_street: order.billingAddress.street,
        billing_city: order.billingAddress.city,
        billing_postcode: order.billingAddress.postcode,
      },
    })
  })
}
