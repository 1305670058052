import { find } from 'lodash'

export default ({ eventBus, eventDefinitions, context }) => {
  let affiliateSrc = 'https://affiliate.sexshop.cz/c3.js'
  let abInstance = 'affiliate.sexshop.cz'
  let abKampan = 3

  if (context.app.$env.SITE === 'intimfitnesscz') {
    affiliateSrc = 'https://partneri.intimfitness.cz/c3.js'
    abInstance = 'partneri.intimfitness.cz'
    abKampan = 6
  } else if (context.app.$env.SITE === 'erossk') {
    affiliateSrc = 'https://affiliate.eros.sk/c3.js'
    abInstance = 'affiliate.eros.sk'
    abKampan = 2
  }

  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order, parameters }) => {
    window.ab_instance = abInstance
    window.ab_kampan = abKampan
    window.ab_mena = order.currencyCode
    window.ab_cena = 0
    window.ab_kupon = order.couponCode || undefined
    window.ab_id_transakce = order.code
    window.ab_polozky = order.items.map(item => {
      const brandId = '5cb738b2e5cc4c0017ea7f53'
      let brand = 'UNKNOWN'
      const brandParameter = find(item.product?.content?.parameters || [], parameter => parameter.entityId === brandId)

      if (brandParameter) {
        const brandValue = brandParameter.values[0]

        if (brandValue) {
          const namedParameter = find(parameters, parameter => parameter.values.includes(brandValue))

          if (namedParameter) {
            brand = find(namedParameter.valueEntities, entity => entity._id === brandValue)?.title
          }
        }
      }

      return {
        produkt: item.name,
        cena: item.prices.totalWithoutTax,
        kategorie: brand,
      }
    })

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = affiliateSrc
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(script, s)
  })
}
