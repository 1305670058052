const middleware = {}

middleware['adjustScroll'] = require('../middleware/adjustScroll.js')
middleware['adjustScroll'] = middleware['adjustScroll'].default || middleware['adjustScroll']

middleware['cookieBot'] = require('../middleware/cookieBot.js')
middleware['cookieBot'] = middleware['cookieBot'].default || middleware['cookieBot']

middleware['fbclidRedirect'] = require('../middleware/fbclidRedirect.js')
middleware['fbclidRedirect'] = middleware['fbclidRedirect'].default || middleware['fbclidRedirect']

middleware['gtm'] = require('../middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['hrkRedirect'] = require('../middleware/hrkRedirect.js')
middleware['hrkRedirect'] = middleware['hrkRedirect'].default || middleware['hrkRedirect']

middleware['localeInPathErase'] = require('../middleware/localeInPathErase.js')
middleware['localeInPathErase'] = middleware['localeInPathErase'].default || middleware['localeInPathErase']

middleware['lowerCaseUrl'] = require('../middleware/lowerCaseUrl.js')
middleware['lowerCaseUrl'] = middleware['lowerCaseUrl'].default || middleware['lowerCaseUrl']

middleware['speedCurveMonitoring'] = require('../middleware/speedCurveMonitoring.js')
middleware['speedCurveMonitoring'] = middleware['speedCurveMonitoring'].default || middleware['speedCurveMonitoring']

middleware['trailingSlashForce'] = require('../middleware/trailingSlashForce.js')
middleware['trailingSlashForce'] = middleware['trailingSlashForce'].default || middleware['trailingSlashForce']

middleware['trailingSlashRemove'] = require('../middleware/trailingSlashRemove.js')
middleware['trailingSlashRemove'] = middleware['trailingSlashRemove'].default || middleware['trailingSlashRemove']

middleware['vivTracking'] = require('../middleware/vivTracking.js')
middleware['vivTracking'] = middleware['vivTracking'].default || middleware['vivTracking']

export default middleware
