import _ from 'lodash'
import brandFragments from '~/queries/fragments/brand'
import gql from 'graphql-tag'

export const actions = {
  async LOAD_BRANDS() {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          ${brandFragments.all}
          query {
            brand(sort: { weight: DESC }) {
              entities {
                ...brandFragmentsAll
              }
              total
            }
          }
        `,
      })
      return result.data.brand.entities
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
