import LayoutBase from 'themes/base/components/shop/LayoutBase'

export default {
  extends: LayoutBase,
  methods: {
    loadCookieConsentComponent() {
      const { source } = this.$store.state.utm.params
      if (
        (this.$store.state.globals.settings.integrations.cookie_bar === '1' || source === 'seznam') &&
        !this.$store.state.globals.cookieConsentClosed
      ) {
        import('@theme/components/shop/CookieConsent').then(component => {
          this.cookieConsentComponent = component.default
        })
      }
    },
  },
}
