// See https://inspirum.teamwork.com/#/tasks/26515118

import { hookDefinitions, hookRegister } from '~/hooks'

hookRegister(hookDefinitions.APP_ROUTE_MIXIN.BEFORE_MOUNT, ({ component }) => {
  if (component.$route.query.IDP) {
    console.log('component.$route.query.IDP', component.$route.query.IDP)
    component.$store.commit('user/SET_FIELD', { field: 'idpCampaignCode', value: component.$route.query.IDP })
  }
})

hookRegister(hookDefinitions.PERSISTENT_STORAGE.PATHS_PROCESS, ({ paths }) => {
  paths.push('user.idpCampaignCode')
})

hookRegister(hookDefinitions.CART.PLACE_ORDER.DATA_PROCESS, ({ actionContext, data }) => {
  data.custom.idpCampaignCode = actionContext.rootState.user.idpCampaignCode
})
