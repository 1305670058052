import ProductBox from '~/themes/base/components/product/ProductBox'

export default {
  extends: ProductBox,
  computed: {
    showsRecommendedPrice() {
      return Boolean(this.activeVariant.prices.attributes?.recommended_price)
    },
  },
}
