import gql from 'graphql-tag'

export default {
  all: gql`
    fragment pageFragmentsAll on page {
      _id
      title
      content
      custom_menu_code
      contact_box_reference {
        entity {
          ... on contact_box {
            description
            show_button
            title_frontend
          }
        }
      }
      page_meta {
        canonical_slugs {
          language
          slug
        }
        meta_nofollow
        meta_noindex
        seo_description
        seo_keywords
        seo_title
        slug
        share_image
      }
      url_rewrite
    }
  `,
  menu: gql`
    fragment pageFragmentsMenu on page {
      _id
      title
      page_meta {
        slug
      }
      url_rewrite
    }
  `,
}
