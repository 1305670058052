<template>
  <div>
    <client-only>
      <notifications
        :duration="5000"
        :max="4"
        classes="notification"
        position="top center" />
      <component
        :is="entryModalComponent"
        :entry-modal-id="'EntryModalCampaign'">
        <div slot="content">
          <component :is="entryModalCampaign"/>
        </div>
      </component>
      <component :is="adminPanelComponent"/>
      <portal-target
        multiple
        name="modal"/>
      <portal-target
        name="fullscreenPresenter"/>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'UtilsLayout',
  data() {
    return {
      adminPanelComponent: null,
      entryModalComponent: null,
      entryModalCampaign: null,
    }
  },
  mounted() {
    if (
      this.$themeSettings.components.EntryModal.enable &&
      this.$store.state.globals.settings.integrations.welcome_campaign === '1' &&
      !this.$store.getters['globals/isEntryModalClosed']('EntryModalCampaign')
    ) {
      import('@theme/components/shop/EntryModalCampaign').then(component => {
        this.entryModalCampaign = component.default
      })
      import('@theme/components/shop/EntryModal').then(component => {
        this.entryModalComponent = component.default
      })
    }
    if (this.$env.ENVIRONMENT_OVERRIDE_ALLOWED) {
      import('@theme/components/shop/AdminPanel').then(component => {
        this.adminPanelComponent = component.default
      })
    }
  },
}
</script>
