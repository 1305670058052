import { isArray, isEmpty, isObject } from 'lodash'

export default ({ eventBus, eventDefinitions }) => {
  eventBus.$on(eventDefinitions.USER.LOADED, ({ user }) => {
    const targitoAttributes = user.attributes?.targito

    if (targitoAttributes) {
      let id = null

      if (isArray(targitoAttributes) && !isEmpty(targitoAttributes)) {
        id = targitoAttributes[0]?.id
      } else if (isObject(targitoAttributes)) {
        id = targitoAttributes.id
      }

      if (id) {
        Targito.push('trigger', 'pair', {
          id,
        })
      }
    }
  })
}
