import { merge } from 'lodash'
import themeSettings from '~/themes/virtshop/settings'

const overloadSettings = {
  components: {
    AddToCartModalItemAdded: {
      displayedProducts: 'recommended',
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageCheckoutFinished: {
      googleSurveyEnabled: true,
    },
    PageHomepage: {
      showHomepageArticles: true,
      showHomepageIntimateAdvisory: true,
    },
    PageProductDetail: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
      showRecommendedCrossSell: true,
    },
    StarRating: {
      activeColor: '#004651',
      inactiveColor: '#dee2e6',
      borderColor: '#004651',
    },
    FooterBottom: {
      showSocialNetworkLinks: false,
    },
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: ['mobile', 'tablet'],
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
