module.exports = {
  defaultCountry: 'CZ',
  defaultLocale: 'cs',
  domainCode: 'virtshop.cz',
  fallbackLocale: 'cs',
  locales: 'cs',
  siteName: 'Sexshop CZ',
  theme: 'sexshopcz',
  targitoURL: 'https://cdn.targito.sexshop.cz/tr.js',
}
