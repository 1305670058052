import * as cookie from 'cookie'
import Cookie from 'js-cookie'
import settings from '@site/content.settings'

export default function({ res, route, store }) {
  if (settings.vivTracking?.enable) {
    // Set server side cookie on request with cjevent query in URL
    if (process.server && route.query['cjevent']) {
      const cookieString = cookie.serialize('cjevent', route.query.cjevent, {
        secure: true,
        path: '/',
        maxAge: 2592000, // 30 days
      })

      res.setHeader('Set-Cookie', cookieString)
    }

    // Set with each request on client
    if (process.client) {
      const cookie = Cookie.get('cjevent')
      const cjevent = route.query['cjevent'] || cookie

      if (cjevent) {
        if (!cookie || cjevent !== cookie) {
          Cookie.set('cjevent', cjevent, {
            expires: 30,
            path: '/',
            secure: true,
          })
        }
      }
    }
  }
}
