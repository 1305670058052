import { hookDefinitions, hookRegister } from '~/hooks'
import gql from 'graphql-tag'

const MATERIAL_PARAMETER_ID = '5cf50646cf12b00022b44327'

hookRegister(hookDefinitions.PAGE.PRODUCT.DETAIL.ASYNC_DATA_PROMISES, ({ context, data, product }) => {
  const materialParameter =
    product.content.parameters &&
    product.content.parameters.find(parameter => parameter.entityId === MATERIAL_PARAMETER_ID)

  if (materialParameter && materialParameter.values && materialParameter.values.length) {
    let client = context.app.apolloProvider.defaultClient
    data.productMaterials = client
      .query({
        query: gql`
          query($ids: [String]) {
            material(filters: { _id: $ids }) {
              entities {
                _id
                material_hygiene
                material_lubricants
                description
              }
            }
          }
        `,
        variables: {
          ids: materialParameter.values,
        },
      })
      .then(result => {
        context.store.commit('product/SET_PAGE_DATA', {
          field: 'productMaterials',
          data: result.data.material.entities,
          page: 'detail',
        })
      })
  } else {
    context.store.commit('product/SET_PAGE_DATA', {
      field: 'productMaterials',
      data: null,
      page: 'detail',
    })
  }
})
