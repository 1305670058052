export const actions = {
  async LOAD_PRODUCTS({ dispatch }, { placement, size, context }) {
    try {
      let recommendations = await dispatch('saimon/GET_RECOMMENDATION', { placement, context }, { root: true })
      let products = await dispatch(
        'product/LOAD_PRODUCTS',
        { codes: (recommendations || []).map(item => item.code) },
        { root: true },
      )
      if (!Array.isArray(products)) {
        products = []
      }
      // Sort results back to API order
      let sorted = []
      recommendations = recommendations || []
      recommendations.forEach(rec => {
        const p = products.find(p => p && p.catalogCode === rec.code)
        if (p) sorted.push(p)
      })
      products = sorted

      // Filter available products
      products = products.filter(product => product.availability.isAvailable)
      products.forEach(product => {
        const recommendationItem = (recommendations || []).find(
          recommendation => recommendation.code === product?.catalogCode,
        )
        product.recommendation = recommendationItem && recommendationItem.recommendation
      })
      return products.slice(0, size)
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({})

export const mutations = {}
