export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PRODUCT.PAGE_VIEW, ({ product, variant }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    window.dataLayer.push({
      event: 'productView',
      ecommerce: {
        detail: {
          products: [
            {
              name: product.name,
              id: product.catalogCode,
              price: unitsWithTax ? product.prices.unitWithTax : product.prices.unitWithoutTax,
              category: product.categoryFullname,
              variant: (variant || {}).name,
              currencyCode: product.prices.currencyCode,
            },
          ],
        },
      },
    })
  })
}
