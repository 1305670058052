import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  name: 'BenefitBodyBlock',
  mixins: [ContentBuilderBlock],
  components: { Wysiwyg },
  cmsSettings: {
    type: 'benefit_body',
  },
  data() {
    return {
      icons: {
        check: require('@icon/heart-check.svg?raw'),
        exclamation: require('@icon/heart-exclamation.svg?raw'),
        question: require('@icon/heart-question.svg?raw'),
        quotes: require('@icon/heart-quotes.svg?raw'),
      },
      iconsAlt: this.$t('BenefitBodyBlock.icons.alt'),
    }
  },
  computed: {
    icon() {
      let icon = 'check'

      if (this.block.icon_type && this.block.icon_type.length > 0) {
        icon = this.block.icon_type[0]
      }

      return icon
    },
  },
  query() {
    return `
      ... on block_benefit_body {
        type
        icon_type
        content
      }
    `
  },
}
