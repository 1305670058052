import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import itemTransformer from './transformers/item.transformer'

eventBus.$on(eventDefinitions.CART.PAGE_VIEW, ({ cart }) => {
  if (Object.keys(cart).length > 0) {
    window.dataLayer.push({
      cart_id: cart.id,
      cart_items: cart.items.filter(item => item.shoppable && item.product).map(item => itemTransformer(item)),
      cart_prices_itemsWithoutTax: cart.prices.itemsWithoutTax,
      cart_prices_itemsWithTax: cart.prices.itemsWithTax,
      cart_prices_totalWithoutTax: cart.prices.totalWithoutTax,
      cart_prices_totalWithTax: cart.prices.totalWithTax,
      cart_productCodes: cart.items.map(item => item.product.marketingCode),
      cart_variantCodes: cart.items.map(item => item.shoppable.marketingCode),
      event: eventDefinitions.CART.PAGE_VIEW,
      totalAmount: cart.items.reduce((acc, item) => acc + item.amount, 0),
    })
  }
})
