import catalogDataFragment from './catalogDataFragment'
import catalogSettingsFragments from './catalogSettings'
import gql from 'graphql-tag'

export default {
  all: gql`
    ${catalogDataFragment.all('virtual_category')}
    ${catalogSettingsFragments.all}
    fragment virtualCategoryFragmentAll on virtual_category {
      _id
      title
      sliced_tags
      parameters {
        entityId
        values
      }
      catalog_settings {
        ...catalogSettingsFragmentAll
      }
      sliced_categories {
        entityId
      }
      sliced_categories_excluded {
        entityId
      }
      price_range {
        ... on price_range {
          price_to
          price_from
          currency_code
        }
      }
      page_meta {
        canonical_slugs {
          language
          slug
        }
        meta_nofollow
        meta_noindex
        seo_description
        seo_keywords
        seo_title
        slug
        share_image
      }
      ...catalogDataFragment_virtual_category
    }
  `,
}
