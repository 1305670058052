import BenefitBodyBlock from '@theme/blocks/BenefitBodyBlock'
import ContentBuilderBlock from '@/mixins/ContentBuilderBlock.vue'
import ProductBox from '@theme/components/product/ProductBox'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  name: 'CuratedGuideProductsBlock',
  components: { BenefitBodyBlock, ProductBox, Wysiwyg },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'curated_guide_products',
  },
  data() {
    return {
      products: [],
    }
  },
  async mounted() {
    this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', {
      ids: this.block.product_reference.map(ref => ref.entityId),
    })
  },
  query() {
    return `
      ... on block_curated_guide_products {
        type
        title
        content,
        benefit_line
        products_list_title
        product_reference {
            entityId
        }
      }
    `
  },
}
